import React from 'react'
import './style.css'

const Section2 = () => {
    return (
        <>
            <div>
                <section id="traders" class="advantages-cards-widget traders snipcss-gGTvv">
                    <div class="advantages-cards-widget__container container">
                        <div class="advantages-cards-widget__inner snipcss0-0-0-1">
                            <div class="advantages-cards-widget__titleset snipcss0-1-1-2">
                                <h2 class="adm-typography adm-typography--displayM adm-text--primary advantages-cards-widget__title snipcss0-2-2-3">
                                    Traders and investors choose Apex to:
                                </h2>
                            </div>
                            <div class="swiper-container advantages-cards-widget__list swiper-container-initialized swiper-container-horizontal snipcss0-1-1-4">
                                <div class="swiper-wrapper snipcss0-2-4-5 style-6SNTq" id="style-6SNTq">
                                    <div class="swiper-slide snipcss0-3-5-6 swiper-slide-active style-2zbv4" id="style-2zbv4">
                                        <div class="advantages-cards-widget__item snipcss0-4-6-7">
                                            <img src="https://static-images.admiralmarkets.com/data/icons/learn.svg" width="48" height="48" loading="lazy" alt="Learn" class="advantages-cards-widget__image snipcss0-5-7-8" />
                                            <div class="advantages-cards-widget__text snipcss0-5-7-9">
                                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle snipcss0-6-9-10">
                                                    Learn
                                                </div>
                                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text snipcss0-6-9-11">
                                                    Learn about trading CFDs and investing in the stock markets with free webinars and live trading sessions with experts, comprehensive Forex courses and much more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide snipcss0-3-5-12 swiper-slide-next style-bkL8a" id="style-bkL8a">
                                        <div class="advantages-cards-widget__item snipcss0-4-12-13">
                                            <img src="https://static-images.admiralmarkets.com/data/icons/trade.svg" width="48" height="48" loading="lazy" alt="Trade" class="advantages-cards-widget__image snipcss0-5-13-14" />
                                            <div class="advantages-cards-widget__text snipcss0-5-13-15">
                                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle snipcss0-6-15-16">
                                                    Trade
                                                </div>
                                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text snipcss0-6-15-17">
                                                    Trade with leverage in any market direction and multiply your trading funds while taking more opportunities.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swiper-slide snipcss0-3-5-18 style-ylI9c" id="style-ylI9c">
                                        <div class="advantages-cards-widget__item snipcss0-4-18-19">
                                            <img src="https://static-images.admiralmarkets.com/data/icons/invest.svg" width="48" height="48" loading="lazy" alt="Invest" class="advantages-cards-widget__image snipcss0-5-19-20" />
                                            <div class="advantages-cards-widget__text snipcss0-5-19-21">
                                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle snipcss0-6-21-22">
                                                    Invest
                                                </div>
                                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text snipcss0-6-21-23">
                                                    If you prefer the classic way of investing and diversifying with Stocks and ETFs, use modern technology and gain an edge while investing in thousands of instruments.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-footer snipcss0-2-4-24">
                                    <div class="advantages-cards-widget__pagination swiper-pagination swiper-pagination-clickable swiper-pagination-bullets snipcss0-3-24-25">
                                        <span class="swiper-pagination-bullet swiper-pagination-bullet-active snipcss0-4-25-26" tabindex="0" role="button" aria-label="Go to slide 1">
                                        </span>
                                    </div>
                                </div>
                                <span class="swiper-notification snipcss0-2-4-27" aria-live="assertive" aria-atomic="true">
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Section2