import React from "react";
import Hero1 from "../../assets/images/hero-1.png";
import Hero2 from "../../assets/images/hero-2.webp";
import "./style.css";
import { Button } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <Header />
      <div className="hero-section">
        <div className="hero-inner d-flex justify-content-between">
          <div className="content-div">
            <h1 className="heading-css">Trade more for less</h1>
            <p className="p-class mt-4">
              More time to trade with extended hours, less trading costs with
              lower spreads
            </p>

            <Link className="class-link" to="/register">
              <Button
                className="signup-button margin-top-5"
                rightIcon={<ArrowForwardIcon />}
                colorScheme="teal"
                variant="outline"
              >
                Signup Now
              </Button>
            </Link>
          </div>
          <div className="image-div"><img className="" height={500} width={500} src={Hero1} alt="hero-1" /></div>
        </div>
      </div>

      <div className="hero-section2">
        <div className="hero-inner2 d-flex justify-content-between">
          <img height={500} width={500} src={Hero2} alt="hero-2" />
          <div className="content-div2">
            <h1 className="heading-css2">Global Markets commission free</h1>
            <h4 className="mt-4 heading-h4">Invest from 1 Euro, Trade from 250 Euro</h4>
            <p className="p-class2 mt-4">
              Hundreds of Crypto & Stock CFDs from various exchanges of the
              world! The offer is eligible for all the Traders all over the
              World.
            </p>

            <Link className="class-link" to="/register">
              <Button
                style={{
                  backgroundColor: "#22B064",
                  width: "120px",
                  padding: "30px",
                  color: "white",
                }}
                className="starrt-button mt-2"
                variant="outline"
              >
                START
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="section-3 mt-5">
        <Section3 />
      </div>

      <div className="section-4 mt-5">
        <Section4 />
      </div>

      <div className="section-5 mt-5">
        <div className="">
          <Section5 />
        </div>
      </div>

      <div className="section-6 mt-5">
        <div className="">
          <Section6 />
        </div>
      </div>

      <div className="section-7 mt-5">
        <div className="">
          <Section7 />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
