import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="">
            <nav className="nav-class navbar navbar-expand-lg navbar-light bg-white" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                <div className="classWrapper">
                    <div>
                        <Link to='/'><div className="navbar-brand" >APEX Trading</div></Link>
                    </div>
                    <div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="ul-class-custom navbar-nav me-auto mb-2 mt-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to='/'> <span className="nav-link active" aria-current="page">Homepage</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/products'> <span className="nav-link">Products</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/education'> <span className="nav-link " href="/" tabIndex="-1" >Education</span></Link>
                        </li>
                        <li className="nav-item mb-2">
                            <Link to='/why-Apex'> <span className="nav-link " href="/" tabIndex="-1" >Why Apex</span></Link>
                        </li>
                    </ul>
                    <form style={{justifyContent: "center"}} className="d-flex ">
                        <Link to='/login'> <button className=" btn btn-outline-success" type="submit">Login</button></Link>
                    </form>
                </div>
            </nav>
        </div>
    );
};

export default Header;
