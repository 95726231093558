import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Section2 = () => {
  return (
    <>
      <div>
        <div class="mt-5 snipcss-QO5DP">
          <div class="container online-trading p-y-lg snipcss0-1-1-2 snipcss-7wWX3">
            <div class="row m-t-md m-b-lg snipcss0-2-2-3">
              <div class="col-sm-12 snipcss0-3-3-4">
                <h2 class="text-center block-title m-b-0 snipcss0-4-4-5">
                  Why online trading?
                </h2>
              </div>
            </div>
            <div class="row snipcss0-2-2-6">
              <div class="col-sm-3 snipcss0-3-6-7">
                <div class="online-trading_item p-a-md p-a-md-xs snipcss0-4-7-8">
                  <svg
                    style={{ display: "flex", width: "100%" }}
                    class="m-b-md snipcss0-5-8-9"
                    height="48"
                    width="36"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 48"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 1 44 L 1 5 C 1 2.79 2.79 1 5 1 L 35 1 L 35 39"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 35 42 L 35 47 L 5 47 C 2.79 47 1 45.21 1 43 L 1 43 C 1 40.79 2.79 39 5 39 L 35 39"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 5 1 L 5 36"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 13 11 L 27 11"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 13 15 L 23 15"
                    ></path>
                  </svg>
                  <p class="description snipcss0-5-8-10">Learn as you go</p>
                </div>
              </div>
              <div class="col-sm-3 snipcss0-3-6-11">
                <div class="online-trading_item p-a-md p-a-md-xs snipcss0-4-11-12">
                  <svg
                    style={{ display: "flex", width: "100%" }}
                    class="m-b-md snipcss0-5-12-13"
                    height="48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 24 17 C 24 19.76 21.76 22 19 22 C 16.24 22 14 19.76 14 17 C 14 14.24 16.24 12 19 12 C 21.76 12 24 14.24 24 17 L 24 17 Z M 24 17"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 34 31 C 34 33.76 31.76 36 29 36 C 26.24 36 24 33.76 24 31 C 24 28.24 26.24 26 29 26 C 31.76 26 34 28.24 34 31 L 34 31 Z M 34 31"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 1.71 46.28 C 0.17 44.77 1.2 41.08 4.11 36.33"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 9.1 41.52 C 4.15 37.31 1 31.02 1 24 C 1 11.3 11.3 1 24 1 C 28.23 1 32.19 2.14 35.6 4.13"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 22.68 13.63 C 33.39 4.23 43.52 -1 46.29 1.72 C 47.83 3.23 46.8 6.92 43.89 11.67"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 39.03 6.59 C 43.91 10.81 47 17.04 47 24 C 47 36.7 36.7 47 24 47 C 19.77 47 15.8 45.86 12.4 43.86"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 25.32 34.37 C 14.61 43.77 4.48 49 1.71 46.28"
                    ></path>
                  </svg>
                  <p class="description snipcss0-5-12-14">
                    Trade from anywhere, any time
                  </p>
                </div>
              </div>
              <div class="col-sm-3 snipcss0-3-6-15">
                <div class="online-trading_item p-a-md p-a-md-xs snipcss0-4-15-16">
                  <svg
                    style={{ display: "flex", width: "100%" }}
                    class="m-b-md snipcss0-5-16-17"
                    height="48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 9 29 L 1 29 L 1 47 L 9 47 L 9 29 Z M 9 29"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 12 31 L 29.86 31 C 31.6 31 33 32.4 33 34.14 L 33 34.14 C 33 35.76 31.76 37.11 30.15 37.26 L 18 39"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 12 45.14 L 23.61 46.93 C 25.3 47.13 27.01 46.9 28.58 46.23 L 45.02 37.33 C 46.41 36.74 47.22 35.23 46.95 33.7 L 46.95 33.7 C 46.64 31.95 45.05 30.77 43.36 31.04 L 33.26 34.65"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 36 14 C 36 21.18 30.18 27 23 27 C 15.82 27 10 21.18 10 14 C 10 6.82 15.82 1 23 1 C 30.18 1 36 6.82 36 14 L 36 14 Z M 36 14"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 20.49 18.33 C 21.02 19.09 21.95 19.6 23 19.6 C 24.66 19.6 26 18.35 26 16.8 C 26 15.25 24.66 14 23 14 C 21.34 14 20 12.75 20 11.2 C 20 9.65 21.34 8.4 23 8.4 C 24.04 8.4 24.95 8.89 25.49 9.63"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 23 8.4 L 23 7"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 23 21 L 23 19.6"
                    ></path>
                  </svg>
                  <p class="description snipcss0-5-16-18">
                    Never miss a trading opportunity
                  </p>
                </div>
              </div>
              <div class="col-sm-3 snipcss0-3-6-19">
                <div class="online-trading_item p-a-md p-a-md-xs snipcss0-4-19-20">
                  <svg
                    style={{ display: "flex", width: "100%" }}
                    class="m-b-md snipcss0-5-20-21"
                    height="48"
                    width="48"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 38"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 47 37 L 1 37 L 1 11 L 47 11 L 47 37 Z M 47 37"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 3 8 L 3 6 L 45 6 L 45 8"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 5 3 L 5 1 L 43 1 L 43 3"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#237dea"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 33 24 C 33 28.97 28.97 33 24 33 C 19.03 33 15 28.97 15 24 C 15 19.03 19.03 15 24 15 C 28.97 15 33 19.03 33 24 L 33 24 Z M 33 24"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 7 11 C 7 14.31 4.31 17 1 17"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 41 11 C 41 14.31 43.69 17 47 17"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 7 37 C 7 33.69 4.31 31 1 31"
                    ></path>
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="2"
                      stroke="#4a5470"
                      fill="none"
                      fill-rule="evenodd"
                      d="M 41 37 C 41 33.69 43.69 31 47 31"
                    ></path>
                  </svg>
                  <p class="description snipcss0-5-20-22">
                    Minimum deposit 100 EUR
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              class="row m-t-lg m-b-md text-center snipcss0-2-2-23"
            >
              <Link to="/register">
                <button
                  class="custom-btn btn-large snipcss0-3-23-24"
                  id="cy-products-start-trading-1"
                >
                  Start Trading
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section2;
