import React, { useState } from "react";
import { Modal, Button, Input, DatePicker, TimePicker } from "antd";
import moment from "moment";
import axios from "axios";
import "./style.css";

const Section7 = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    phone: "",
    date: null,
    time: null,
    comment: "",
  });
  const [formError, setFormError] = useState("");

  const handleInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    // Check if all fields are filled
    for (const key in formData) {
      if (!formData[key]) {
        setFormError(`Please fill in ${key}`);
        return;
      }
    }
    setFormError("");

    // Format date and time before saving
    const formattedDate = formData.date
      ? moment(formData.date).format("YYYY-MM-DD")
      : null;
    const formattedTime = formData.time
      ? moment(formData.time).format("hh:mm A")
      : null;

    // Send form data to WebS3Forms email service
    axios
      .post("https://api.webs3forms.com/post", {
        accessKey: "26570a79-fbcc-423b-a84e-d49abc9eddce", // Your access key
        ...formData,
        date: formattedDate,
        time: formattedTime,
      })
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        setModalVisible(false); // Close modal after successful submission
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        // Handle error here
      });
  };

  return (
    <div>
      <section className="in-touch snipcss-LHUDl">
        <div className="container">
          <div className="in-touch__wrapper">
            <div className="in-touch__image" data-text="Hello!">
              <img
                src="https://dynamic-images.admiralmarkets.com/670x,webp/static-images.admiralmarkets.com/data/homepage/flat-phone.png"
                alt="Get in touch"
                loading="lazy"
                width="521"
                height="328"
              />
            </div>
            <div className="in-touch__info">
              <h2>Get in touch</h2>
              <p>More questions? Contact us today!</p>
              <button
                id="jrd-home-contact-us-1"
                className="mdc-button action-link mdc-button--color-positive mdc-button--unelevated mdc-button--large mdc-ripple-upgraded"
                color="positive"
                size="large"
                onClick={() => setModalVisible(true)}
              >
                <span className="mdc-button__label">Contact us</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        title="Contact Us"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <form
          layout="vertical"
          action="https://api.web3forms.com/submit"
          method="POST"
          id="form"
        >
          <Input
            type="hidden"
            name="access_key"
            value="26570a79-fbcc-423b-a84e-d49abc9eddce"
          />
          <Input type="hidden" name="subject" value="Subject" />
          <Input
            placeholder="Full name"
            value={formData.name}
            required
            name="first_name"
            type="text"
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            style={{ marginBottom: "12px" }}
          />
          <Input
            placeholder="example@gmail.com"
            value={formData.email}
            name="email"
            type="email"
            onChange={(e) => handleInputChange("email", e.target.value)}
            style={{ marginBottom: "12px" }}
            required
          />
          <Input
            placeholder="+1 (555) 1234-567"
            name="phone"
            value={formData.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            style={{ marginBottom: "12px" }}
            required
          />
          <DatePicker
            placeholder="Date"
            name="date"
            value={formData.date}
            required
            onChange={(date) => handleInputChange("date", date)}
            style={{ marginBottom: "12px" }}
          />
          <TimePicker
            placeholder="Time"
            name="time"
            value={formData.time}
            required
            onChange={(time) => handleInputChange("time", time)}
            format="hh:mm A"
            style={{ marginBottom: "12px" }}
          />
          <Input.TextArea
            placeholder="Comment"
            name="Comment"
            value={formData.comment}
            required
            onChange={(e) => handleInputChange("comment", e.target.value)}
            style={{ marginBottom: "12px", height: "100px" }}
          />

          <div className="mb-6 mt-3 text-center">
            <button type="submit" className="form-button third2">
              Send Message
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Section7;
