import axios from 'axios';


// const baseURL = "http://localhost:5000/api";
const baseURL = "https://backend.app.apexmarkets.pro/api";

const instance = axios.create({
  // baseURL: 'http://localhost:5000/api',
  baseURL: 'https://backend.app.apexmarkets.pro/api',
});

export default instance;


//User API's 

//GET USER
export const getUsers = () => {
    return axios.get(`${baseURL}/users`);
  };
  
  //GET USER BY ID
  export const getUserById = (id) => {
    return axios.get(`${baseURL}/users/${id}`);
  };
  
  //CREATE USER
  export const addUser = (userData) => {
    return axios.post(`${baseURL}/users`, userData);
  };
  
  
  //UPDATE USER
  export const updateUser = (userId, updatedUserData) => {
    return axios.put(`${baseURL}/users/${userId}`, updatedUserData);
  };

  //DELETE USER
export const deleteUser = (userId) => {
    return axios.delete(`${baseURL}/users/${userId}`);
  };