import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Section3 = () => {
  return (
    <>
      <div>
        <section class="why-choose-am p-y-lg snipcss-5kWvj">
          <div class="container">
            <div class="row m-t-md m-b-lg">
              <div class="col-sm-12">
                <h2 class="text-center section-title text-white">
                  Why choose Apex?
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5 col-sm-offset-1">
                <ul class="ul-unstyle text-white">
                  <li>Regulated by CySEC</li>
                  <li>Negative Balance Protection Policy</li>
                  <li>Leverage up to 1:500 (Retail - 1:30)</li>
                  <li>Minimum deposit 100 EUR</li>
                  <li>Spread from 0 pips</li>
                </ul>
              </div>
              <div class="col-sm-5">
                <ul class="ul-unstyle text-white">
                  <li>Free market news and analysis by Dow Jones</li>
                  <li>No restrictions on trading styles or strategies</li>
                  <li>Deep liquidity from top tier providers</li>
                  <li>90% of orders executed within 150 milliseconds</li>
                  <li>MetaTrader 4 and MetaTrader 5</li>
                </ul>
              </div>
            </div>
            <div
              style={{}}
              class="d-flex justify-content-center row m-b-md m-t-lg text-center"
            >
              <Link to="/register">
                <button
                  style={{ width: "fit-content" }}
                  class="button is-normal slide-button rounded-btn-success  style-bbYis"
                  id="cy-products-open-account-1"
                  href="#"
                >
                  Open Account
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Section3;
