import React from 'react';
import './style.css'
import { Link } from 'react-router-dom';

function AdmiralItem({ imageSrc, altText, title, description, link }) {
    return (
        <div className="choose__item">
            <div className="choose__item-image">
                <img src={imageSrc} alt={altText} loading="lazy" />
            </div>
            <h3 className="choose__item-title">{title}</h3>
            <p className='choose__item-p'>{description}</p>
            {link && (
                <Link to={link.to} className={link.className} >
                    {link.text}
                </Link>
            )}
        </div>
    );
}

function SliderItem({ children }) {
    return <>{children}</>;
}

function Slider({ children }) {
    return (
        <div className="choose__items-mobile">
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, { key: index });
            })}
            <div className="swiper-pagination" slot="pagination"></div>
        </div>
    );
}

function Section5() {
    return (
        <div className="mt-5 pt-5 container snipcss-khTey">
            <h2 style={{ fontSize: "40px", textAlign: "center" }}>Why choose Apex?</h2>
            <div className="mt-5 pt-4 choose__items choose__items-desktop">
                <AdmiralItem
                    imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/location.svg"
                    altText="We are global"
                    title="We are global"
                    description="Get Support Everywhere
          We have a team of people
          Who Speak Different Language
          Via Phone or Email
          "
                />
                <AdmiralItem
                    imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/broker.svg"
                    altText="We are regulated"
                    title="We are regulated"
                    description="We are licensed by the world`s top regulators, in the UK, Estonia, Cyprus, Australia and Jordan"
                />
                <AdmiralItem
                    imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/security.svg"
                    altText="Funds are secured"
                    title="Funds are secured"
                    description="All client deposits are kept separate from our own operating funds, plus we offer extra protection in a volatile market"
                />
                <AdmiralItem
                    imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/work-green.svg"
                    altText="Start from $250"
                    title="Start from $250"
                    description="You can start trading from $250"
                    link={{
                        to: "/register",
                        className:
                            "mdc-button action-link mdc-button--color-positive mdc-button--large mdc-button--text mdc-ripple-upgraded",
                        text: "Start now",
                    }}
                />
            </div>
            <Slider>
                <SliderItem>
                    <AdmiralItem
                        imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/location.svg"
                        altText="We are global"
                        title="We are global"
                        description="Get support in your language, with 16 local offices and multilingual client support via phone, email and live chat"
                    />
                </SliderItem>
                <SliderItem>
                    <AdmiralItem
                        imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/broker.svg"
                        altText="We are regulated"
                        title="We are regulated"
                        description="We are licensed by the world`s top regulators, in the UK, Estonia, Cyprus, Australia and Jordan"
                    />
                </SliderItem>
                <SliderItem>
                    <AdmiralItem
                        imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/security.svg"
                        altText="Funds are secured"
                        title="Funds are secured"
                        description="All client deposits are kept separate from our own operating funds, plus we offer extra protection in a volatile market"
                    />
                </SliderItem>
                <SliderItem>
                    <AdmiralItem
                        imageSrc="https://static-images.admiralmarkets.com/images/elements/stocks/work-green.svg"
                        altText="Start from $250"
                        title="Start from $250"
                        description="You can start trading from $25"
                        link={{
                            href: "https://admiralmarkets.com/signup?language=en",
                            id: "jrd-home-start-now-2",
                            className:
                                "mdc-button action-link mdc-button--color-positive mdc-button--large mdc-button--text mdc-ripple-upgraded",
                            text: "Start now",
                        }}
                    />
                </SliderItem>
            </Slider>
        </div>
    );
}

export default Section5;
