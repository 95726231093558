import React from 'react'
import Header from '../Header'
import Section1 from './Section1'
import Section2 from './Section2'
import Footer from '../Footer'

const Education = () => {
  return (
    <div>
      <Header />
      <Section1 />
      <Section2 />
      <Footer/>
    </div>
  )
}

export default Education