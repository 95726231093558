import React from 'react'
import './style.css'

const Section4 = () => {
    return (
        <>
            <div className='container'>
                <div class="advantages-cards-widget__inner snipcss-qMfQ5">
                    <div class="advantages-cards-widget__titleset">
                        <h2 class="adm-typography adm-typography--displayM adm-text--primary advantages-cards-widget__title">
                            How we make a difference                        </h2>
                    </div>
                    <div class="advantages-cards-widget__list">
                        <div class="advantages-cards-widget__item">
                            <img class="advantages-cards-widget__image" src="https://static-images.admiralmarkets.com/data/icons/unite.svg" width="48" height="48" loading="lazy" alt="We Unite" />
                            <div class="advantages-cards-widget__text">
                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle">
                                    We Unite
                                </div>
                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text">
                                    We unite trader, investors and personal financial Advisers.
                                </p>
                            </div>
                        </div>
                        <div class="advantages-cards-widget__item">
                            <img class="advantages-cards-widget__image" src="https://static-images.admiralmarkets.com/data/icons/simplify.svg" width="48" height="48" loading="lazy" alt="We Simplify" />
                            <div class="advantages-cards-widget__text">
                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle">
                                    We Simplify
                                </div>
                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text">
                                    We simplify how to manage your funds and how you can learn more about taking the right decision when trading
                                </p>
                            </div>
                        </div>
                        <div class="advantages-cards-widget__item">
                            <img class="advantages-cards-widget__image" src="https://static-images.admiralmarkets.com/data/icons/connect.svg" width="48" height="48" loading="lazy" alt="We Connect" />
                            <div class="advantages-cards-widget__text">
                                <div class="adm-typography adm-typography--h2 adm-text--title advantages-cards-widget__subtitle">
                                    We Connect
                                </div>
                                <p class="adm-typography adm-typography--bodyL adm-text--primary advantages-cards-widget__text">
                                    We connect the world with our global expertise, access and offer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section4