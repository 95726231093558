import React from 'react'
import './style.css'

const Footer = () => {
    return (
        <>
            <div className=" mt-5 pt-5 container snipcss-4DT7F">
                <div className="am-footer-nav">
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                Start Trading
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Invest in Stocks & ETFs
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Demo Trading Account
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Trading Calculator
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Account Types
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Deposits & Withdrawals
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Pro
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Fractional Investing
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Islamic Forex Account
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Documents & Policies
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Professional Trading Terms
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Asset Management
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                Platforms
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        MetaTrader 5
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        MetaTrader 4
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        MetaTrader WebTrader
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Mobile App
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Platform
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        MetaTrader Supreme Edition
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        StereoTrader
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Virtual Private Server
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Parallels for MAC
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                Products
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Forex
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Commodities
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Indices
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Stocks
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        ETFs
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Bonds
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Cryptocurrencies
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Contract Specifications
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Margin Requirements
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Volatility Protection
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Card
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Wallet
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex Card Fees
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                Education
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Webinars
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Articles & Tutorials
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Zero to Hero
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Forex 101
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Trading Videos
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Risk Management
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Trader's Glossary
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        FAQ
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        E-books
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                Analytics
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Forex Calendar
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Trading News
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Global Market Updates
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Premium Analytics
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Weekly Trading Podcast
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Fundamental Analysis
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Market Heat Map
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Market Sentiment
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Trading Central
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="am-footer-nav__block">
                        <div className="am-footer-nav__block-title">
                            <h6>
                                About Apex
                            </h6>
                        </div>
                        <div className="am-footer-nav__block-main">
                            <ul className="am-footer-nav__sub-links">
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Why Apex?
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Regulation
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Financial Security
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Secure your trading account
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Contact Apex
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Company News
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Rebranding
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Apex is green
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Press Centre
                                    </p>
                                </li>
                                <li className="am-footer-nav__sub-link-item">
                                    <p>
                                        Careers
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div>
                        <div class="am-footer-rw snipcss0-0-0-1 snipcss-7zIXX">
                            <div class="am-footer-top-line snipcss0-1-1-2">
                                <p class="snipcss0-2-2-3">
                                    <strong class="snipcss0-3-3-4">
                                        Risk warning: Trading Forex (foreign exchange) or CFDs (contracts for difference) on margin/Leverage carries a high degree of risk and might not be Profitable for all investors. There is a possibility that you may incur a loss equal to or greater than your entire investment. They is why you are not advised to risk money that you cannot afford to lose. Before using Apex Markets UK Ltd, Apex Europe Ltd (previously Apex Markets Cyprus Ltd), Apex Markets AS Jordan Ltd, Apex AU Pty Ltd and Apex SA (PTY) Ltd services, please acknowledge all of the risks associated with trading.                                    </strong>
                                </p>
                                <p class="snipcss0-2-2-5">
                                    <strong class="snipcss0-3-5-6">
                                        <b class="snipcss0-4-6-7">
                                            The content of this website must not be construed as personal advice. We recommend that you seek advice from one of our licensed financial advisers or your own independent financial adviser                                         </b>
                                    </strong><br></br><br></br>
                                    <strong class="snipcss0-3-5-6">
                                        <b class="snipcss0-4-6-7">
                                            Product offer may differ depending on the regulatory requirements of each Operating Company. </b>                                    </strong>
                                </p>
                            </div>
                            <div class="am-footer-top-line snipcss0-1-1-8">
                                <p class="snipcss0-2-8-9">
                                    <strong class=" snipcss0-3-9-10">
                                        Apex Markets UK Ltd
                                    </strong>
                                      is registered in England and Wales under Companies House – registration number 08173345. Apex Markets UK Ltd is authorised and regulated by the Financial Conduct Authority (FCA) – registration number 515252
                                </p>
                            </div>
                            <div class="am-footer-top-line am-footer-info snipcss0-1-1-11">
                                <div class="footer-contact-details snipcss0-2-11-12">
                                </div>
                                <ul class="snipcss0-2-11-13">
                                    <li class="snipcss0-3-13-14">
                                        <button target="_blank" class="link snipcss0-4-14-15">
                                            <b class="snipcss0-5-15-16">
                                                Privacy Policy
                                            </b>
                                        </button>
                                    </li>
                                    <li class="snipcss0-3-13-17">
                                        <button class="ot-sdk-show-settings link snipcss0-4-17-18 style-Mngyf" id="style-Mngyf">
                                            Client Complaints Handling Procedure
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default Footer
