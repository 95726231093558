import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Section1 = () => {
    return (
        <>
            <div style={{width: "100%", backgroundColor: "#F8F8FB"}}>
                <div className="container adaptive-hero-banner__container snipcss-mo1Ho">
                    <div className="adaptive-hero-banner__item adaptive-hero-banner__inner">
                        <div className="adm-typography adm-typography--bodyL adm-text--primary adaptive-hero-banner__subtitle">
                            Learn. Trade. Invest.
                        </div>
                        <h1 className="mt-3 adm-typography adm-typography--displayL adm-text--title adaptive-hero-banner__title">
                            Apex Provides Best Answers for your financial Needs
                        </h1>
                        <div className="adaptive-hero-banner__description">
                            <p className='mt-3'>
                                Since 1996, we have committed to empowering each and every individual's financial Needs, providing attractive trading conditions and advisers, combined with an outstanding trading and investing ecosystem.
                            </p>
                        </div>
                        <div className="adaptive-hero-banner__action">
                            <div className="adaptive-hero-banner__action-btn">
                                <Link to='/register' id="btn_try_demo_trading" href="#" className="adm-button adm-button--primary adm-button--primary-default adm-button--large adm-button--inline adm-button--without-icon">
                                    <span className="adm-button__label">
                                        Try Demo Trading
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="adaptive-hero-banner__item adaptive-hero-banner__image">
                        <picture>
                            <img src="https://dynamic-images.admiralmarkets.com/1392x,webp/static-images.admiralmarkets.com/data/why-us/hero.png" alt="Admirals provides Smart Financial Answers for You!" width="350" height="346" />
                        </picture>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section1