import React from 'react'
import './style.css'

const Section1 = () => {
    return (
        <>
            <div>
                <div class="mt-5 row snipcss-77v72">
                    <div class="col-sm-10 col-sm-offset-1">
                        <h1 class="page-heading text-center p-t">
                            Our products
                        </h1>
                        <p class="page-description text-center p-t-xs">
                            Apex gives you access to the universe of online trading, making global markets immediately available wherever and whenever you decide to experience the lifestyle of a modern trader
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section1