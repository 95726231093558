import React from 'react'
import './style.css'

const Section3 = () => {
    return (
        <>
            <div className='container'>
                <div class="commits__inner snipcss-hdsQR">
                    <h2 class="adm-typography adm-typography--displayM adm-text--title commits__title">
                        Apex Commits to you
                    </h2>
                    <div class="mt-5 commits__list">
                        <div class="commits__item commit">
                            <div class="commit__content">
                                <h2 class="adm-typography adm-typography--h1 adm-text--title commit__title">
                                    Security and Trust Apex                                </h2>
                                <div data-v-5eba622c="" class="commit__description closed details-widget">
                                    <div data-v-5eba622c="" class="details-widget__content">
                                        <div data-v-5eba622c="" class="details-widget__summary">
                                            <div data-v-5eba622c="" class="adm-typography adm-typography--bodyL adm-text--primary commit__summary">
                                                A company with a global presence, has been a trustworthy and reliable provider for its clients since 1996.With Apex, clients have access to robust financial security arrangements and various customer care policies.                                            </div>
                                        </div>
                                        <div data-v-5eba622c="" class="details-widget__details">
                                            <br></br>
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                Some prominent security benefits we offer:
                                            </div>
                                            <ul class="commit__paragraph">
                                                <li class="adm-typography adm-typography--bodyL adm-text--primary">
                                                    Client funds are always available to them and are held in the most reputable Tier 1 banks in segregated accounts separately from our company funds.
                                                </li>
                                                <li class="adm-typography adm-typography--bodyL adm-text--primary">
                                                    Additional funds insurance coverage up to 100,000 USD.
                                                </li>
                                                <li class="adm-typography adm-typography--bodyL adm-text--primary">
                                                    100% of all our clients will be covered via the insurance policy
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <picture class="commit__image">
                                <img src="https://dynamic-images.admiralmarkets.com/904x,webp/static-images.admiralmarkets.com/data/why-us/trust.png" alt="We support financial education" loading="lazy" width="200" height="266" />
                            </picture>
                        </div>
                        <div class="commits__item commit">
                            <div class="commit__content">
                                <h2 class="adm-typography adm-typography--h1 adm-text--title commit__title">
                                    Financial Instruments.
                                    <br></br>
                                    First-Class Conditions.
                                </h2>
                                <div data-v-5eba622c="" class="commit__description closed details-widget">
                                    <div data-v-5eba622c="" class="details-widget__content">
                                        <div data-v-5eba622c="" class="details-widget__summary">
                                            <div data-v-5eba622c="" class="adm-typography adm-typography--bodyL adm-text--primary commit__summary">
                                                Apex offers clients opportunities to trade and invest in thousands of trading instruments
                                            </div>
                                        </div>
                                        <div data-v-5eba622c="" class="details-widget__details">
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                including  Crypto and ETFs, Forex pairs, CFDs on indices, commodities and bonds.


                                            </div>
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                We also offer Fractional shares – trade your favorite blue-chip stocks at a fraction of the price! Apex is a Straight Through Processing (STP) provider, meaning an ultra-fast market execution experience.                                            </div>
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                Active traders choose us for our competitive spreads and commissions. We make every effort to keep trading costs affordable while providing value with seamless transaction management using the most sophisticated trading and investing software available on the market.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <picture class="commit__image">
                                <img src="https://dynamic-images.admiralmarkets.com/1388x,webp/static-images.admiralmarkets.com/data/why-us/instruments.png" alt="Financial Instruments." loading="lazy" width="200" height="266" />
                            </picture>
                        </div>
                        <div class="commits__item commit">
                            <div class="commit__content">
                                <h2 class="adm-typography adm-typography--h1 adm-text--title commit__title">
                                    We support financial education
                                </h2>
                                <div data-v-5eba622c="" class="commit__description closed details-widget">
                                    <div data-v-5eba622c="" class="details-widget__content">
                                        <div data-v-5eba622c="" class="details-widget__summary">
                                            <div data-v-5eba622c="" class="adm-typography adm-typography--bodyL adm-text--primary commit__summary">
                                                We provide our clients with services beyond trading and investing.
                                            </div>
                                        </div>
                                        <div data-v-5eba622c="" class="details-widget__details">
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                The financial world can be daunting but with the right educational support, our clients can make informed decisions.
                                            </div>
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                Whether you are a novice in the world of trading and investing or a professional in the field who likes to be on top of the daily trends, Apex provides knowledge to both via our carefully designed online courses, seminars, webinars, and educational articles
                                            </div>
                                            <div class="adm-typography adm-typography--bodyL adm-text--primary commit__paragraph">
                                                We also keep our clients up to date with daily investment news and analysis to trade smartly. Learn from Ebooks, stay ahead with daily trading and investing news, video tutorials,
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <picture class="commit__image">
                                <img src="https://dynamic-images.admiralmarkets.com/904x,webp/static-images.admiralmarkets.com/data/why-us/support.png" alt="We support financial education" loading="lazy" width="200" height="266" />
                            </picture>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section3