import React from "react";
import './style.css'

const SingleUserData = ({singleUser}) => {
  const userData = [
    {
      title: "Account Balance",
      amount: `${singleUser?.balance}`,
      color: "primary",
      icon: "wallet-fill",
      link1: { text: "Deposit", url: "#" },
      link2: { text: "Transfer", url: "#" }
    },
    {
      title: "Total Profit",
      amount: `${singleUser?.total_price}`,
      color: "success",
      icon: "coin"
    },
    {
      title: "Total Bonus",
      amount: `${singleUser?.total_bonus}`,
      color: "info",
      icon: "gift-fill"
    },
    {
      title: "Referral Bonus",
      amount: `${singleUser?.referal_bonus}`,
      color: "info",
      icon: "piggy-bank"
    },
    {
      title: "Total Deposited",
      amount: `${singleUser?.total_deposited}`,
      color: "success",
      icon: "box-arrow-in-down",
      link1: { text: "View deposits", url: "#" }
    },
    {
      title: "Total Withdrawal",
      amount: `${singleUser?.total_withdraw}`,
      color: "danger",
      icon: "box-arrow-in-up"
    },
    {
      title: "Referrals",
      color: "secondary",
      icon: "link"
    },
    {
      title: "Managed Accounts",
      amount: `${singleUser?.managed_accounts}`,
      color: "info",
      icon: "bar-chart-steps",
      link1: { text: "View accounts", url: "#" }
    }
  ];

  return (
    <div className="outer-wrapper">
      <div>
        <div className="row">
          {userData.map((item, index) => (
            <div className="col-lg-3" key={index}>
              <div className="card border-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <div>
                        <h5 className="card-title d-flex align-items-center text-uppercase text-muted fw-semibold mb-2">
                          <span className={`legend-circle-sm bg-${item.color}`}></span>{" "}
                          {item.title}
                        </h5>
                        {item.amount && <h2 className="amount mb-0"> ${item.amount}.00 </h2>}
                        {item.link1 && (
                          <a href={item.link1.url} className="link btn-link mb-0 mt-2 mr-3">
                            {" "}
                            {item.link1.text}{" "}
                          </a>
                        )}
                        {item.link2 && (
                          <a href={item.link2.url} className="link btn-link mb-0 mt-2 ml-3">
                            {" "}
                            {item.link2.text}{" "}
                          </a>
                        )}
                      </div>
                      {item.amount && (
                        <span className={`icon text-${item.textColor}`}>
                          <i className={`icon bi bi-${item.icon} fs-1`}></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleUserData;
