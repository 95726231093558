// authService.js

import api from "./api";
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const storedUser = Cookies.get("user");
    const storedUid = Cookies.get("uid");

    const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);
    const [uid, setUid] = useState(storedUser);

    const login = async (credentials) => {
        try {
            const response = await api.post("/login", credentials);
            setUser(response?.data);
            Cookies.set("uid", (response?.data?.user?._id))
            // Cookies.set('user', JSON.stringify(response?.data));
            Cookies.set('isAdmin', (response?.data?.user?.isAdmin))
            return response;
        } catch (error) {
            console.error("Login error:", error);
            return error;
        }
    };

    const register = async (data) => {
        try {
            const response = await api.post('/register', data);
            console.log(response)
            if (response.status === 201) {
                // Handle successful signup
                navigate('/');
                return response;
            } else {
                // Handle error
                const errorData = await response.json();
                console.error('Signup failed:', errorData.error);
                return null;
            }
        } catch (error) {
            console.error('Signup error:', error.response.data.error);
            return error;
        }
    };


    const logout = () => {
        setUser(null);
        Cookies.remove('user')
        Cookies.remove('uid')
        Cookies.remove('isAdmin')
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, register, uid }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
