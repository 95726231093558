import React, { useState } from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import Select from "react-select";
import "./style.css";
import { message } from 'antd'

import BackgroundImage from "../../assets/images/background.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../service/authService";
import { useNavigate } from 'react-router-dom'

const Register = () => {
    const [inputUsername, setInputUsername] = useState("");
    const [inputFullName, setInputFullName] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [inputCountry, setInputCountry] = useState("");
    const [inputReferralId, setInputReferralId] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { register } = useAuth()
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        // setLoading(true);
        if (inputPassword !== confirmPassword) {
            setShowError(true);
            setLoading(false);
            return;
        }
        const userData = {
            username: inputUsername,
            full_name: inputFullName,
            email: inputEmail,
            phone_number: inputPhone,
            country: inputCountry.label,
            referral_id: inputReferralId,
            password: inputPassword
        };
        try {
            await register(userData).then((response) => {
                if (response.status === 201) {
                    message.success("User successfully registered");
                    navigate('/login')
                }
                else {
                    message.error(response.response?.data);
                }
            })
        } catch (error) {
            // Error occurred during registration
            message.error('Registration failed');
        }
        setLoading(false);
    };

    const handlePassword = () => { };


    // Options for the country dropdown
    const countries = [
        { label: "Afghanistan" },
        { label: "Albania" },
        { label: "Algeria" },
        { label: "Andorra" },
        { label: "Angola" },
        { label: "Antigua and Barbuda" },
        { label: "Argentina" },
        { label: "Armenia" },
        { label: "Australia" },
        { label: "Austria" },
        { label: "Azerbaijan" },
        { label: "Bahamas" },
        { label: "Bahrain" },
        { label: "Bangladesh" },
        { label: "Barbados" },
        { label: "Belarus" },
        { label: "Belgium" },
        { label: "Belize" },
        { label: "Benin" },
        { label: "Bhutan" },
        { label: "Bolivia" },
        { label: "Bosnia and Herzegovina" },
        { label: "Botswana" },
        { label: "Brazil" },
        { label: "Brunei" },
        { label: "Bulgaria" },
        { label: "Burkina Faso" },
        { label: "Burundi" },
        { label: "Cabo Verde" },
        { label: "Cambodia" },
        { label: "Cameroon" },
        { label: "Canada" },
        { label: "Central African Republic" },
        { label: "Chad" },
        { label: "Chile" },
        { label: "China" },
        { label: "Colombia" },
        { label: "Comoros" },
        { label: "Congo (Congo-Brazzaville)" },
        { label: "Costa Rica" },
        { label: "Croatia" },
        { label: "Cuba" },
        { label: "Cyprus" },
        { label: "Czechia (Czech Republic)" },
        { label: "Democratic Republic of the Congo" },
        { label: "Denmark" },
        { label: "Djibouti" },
        { label: "Dominica" },
        { label: "Dominican Republic" },
        { label: "East Timor (Timor-Leste)" },
        { label: "Ecuador" },
        { label: "Egypt" },
        { label: "El Salvador" },
        { label: "Equatorial Guinea" },
        { label: "Eritrea" },
        { label: "Estonia" },
        { label: "Eswatini (fmr. Swaziland)" },
        { label: "Ethiopia" },
        { label: "Fiji" },
        { label: "Finland" },
        { label: "France" },
        { label: "Gabon" },
        { label: "Gambia" },
        { label: "Georgia" },
        { label: "Germany" },
        { label: "Ghana" },
        { label: "Greece" },
        { label: "Grenada" },
        { label: "Guatemala" },
        { label: "Guinea" },
        { label: "Guinea-Bissau" },
        { label: "Guyana" },
        { label: "Haiti" },
        { label: "Honduras" },
        { label: "Hungary" },
        { label: "Iceland" },
        { label: "India" },
        { label: "Indonesia" },
        { label: "Iran" },
        { label: "Iraq" },
        { label: "Ireland" },
        { label: "Israel" },
        { label: "Italy" },
        { label: "Ivory Coast" },
        { label: "Jamaica" },
        { label: "Japan" },
        { label: "Jordan" },
        { label: "Kazakhstan" },
        { label: "Kenya" },
        { label: "Kiribati" },
        { label: "Kosovo" },
        { label: "Kuwait" },
        { label: "Kyrgyzstan" },
        { label: "Laos" },
        { label: "Latvia" },
        { label: "Lebanon" },
        { label: "Lesotho" },
        { label: "Liberia" },
        { label: "Libya" },
        { label: "Liechtenstein" },
        { label: "Lithuania" },
        { label: "Luxembourg" },
        { label: "Madagascar" },
        { label: "Malawi" },
        { label: "Malaysia" },
        { label: "Maldives" },
        { label: "Mali" },
        { label: "Malta" },
        { label: "Marshall Islands" },
        { label: "Mauritania" },
        { label: "Mauritius" },
        { label: "Mexico" },
        { label: "Micronesia" },
        { label: "Moldova" },
        { label: "Monaco" },
        { label: "Mongolia" },
        { label: "Montenegro" },
        { label: "Morocco" },
        { label: "Mozambique" },
        { label: "Myanmar (formerly Burma)" },
        { label: "Namibia" },
        { label: "Nauru" },
        { label: "Nepal" },
        { label: "Netherlands" },
        { label: "New Zealand" },
        { label: "Nicaragua" },
        { label: "Niger" },
        { label: "Nigeria" },
        { label: "North Korea" },
        { label: "North Macedonia (formerly Macedonia)" },
        { label: "Norway" },
        { label: "Oman" },
        { label: "Pakistan" },
        { label: "Palau" },
        { label: "Palestine State" },
        { label: "Panama" },
        { label: "Papua New Guinea" },
        { label: "Paraguay" },
        { label: "Peru" },
        { label: "Philippines" },
        { label: "Poland" },
        { label: "Portugal" },
        { label: "Qatar" },
        { label: "Romania" },
        { label: "Russia" },
        { label: "Rwanda" },
        { label: "Saint Kitts and Nevis" },
        { label: "Saint Lucia" },
        { label: "Saint Vincent and the Grenadines" },
        { label: "Samoa" },
        { label: "San Marino" },
        { label: "Sao Tome and Principe" },
        { label: "Saudi Arabia" },
        { label: "Senegal" },
        { label: "Serbia" },
        { label: "Seychelles" },
        { label: "Sierra Leone" },
        { label: "Singapore" },
        { label: "Slovakia" },
        { label: "Slovenia" },
        { label: "Solomon Islands" },
        { label: "Somalia" },
        { label: "South Africa" },
        { label: "South Korea" },
        { label: "South Sudan" },
        { label: "Spain" },
        { label: "Sri Lanka" },
        { label: "Sudan" },
        { label: "Suriname" },
        { label: "Sweden" },
        { label: "Switzerland" },
        { label: "Syria" },
        { label: "Taiwan" },
        { label: "Tajikistan" },
        { label: "Tanzania" },
        { label: "Thailand" },
        { label: "Togo" },
        { label: "Tonga" },
        { label: "Trinidad and Tobago" },
        { label: "Tunisia" },
        { label: "Turkey" },
        { label: "Turkmenistan" },
        { label: "Tuvalu" },
        { label: "Uganda" },
        { label: "Ukraine" },
        { label: "United Arab Emirates" },
        { label: "United Kingdom" },
        { label: "United States" },
        { label: "Uruguay" },
        { label: "Uzbekistan" },
        { label: "Vanuatu" },
        { label: "Vatican City" },
        { label: "Venezuela" },
        { label: "Vietnam" },
        { label: "Yemen" },
        { label: "Zambia" },
        { label: "Zimbabwe" }
    ];


    return (
        <div
            className="sign-in__wrapper"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
            <div className="sign-in__backdrop"></div>
            <Form className="form-div shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                <div className="h4 mb-4 text-center">Sign Up</div>
                {showError && (
                    <Alert
                        className="mb-2"
                        variant="danger"
                        onClose={() => setShowError(false)}
                        dismissible
                    >
                        Passwords do not match.
                    </Alert>
                )}
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputUsername}
                                placeholder="Username"
                                onChange={(e) => setInputUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputFullName}
                                placeholder="Full Name"
                                onChange={(e) => setInputFullName(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={inputEmail}
                                placeholder="Email"
                                onChange={(e) => setInputEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="phone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                value={inputPhone}
                                placeholder="Phone Number"
                                onChange={(e) => setInputPhone(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Select
                                options={countries}
                                value={inputCountry}
                                onChange={(selectedOption) => setInputCountry(selectedOption)}
                                placeholder="Select Country"
                                isSearchable
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="referralId">
                            <Form.Label>Referral ID</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputReferralId}
                                placeholder="Referral ID"
                                onChange={(e) => setInputReferralId(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={inputPassword}
                                placeholder="Password"
                                onChange={(e) => setInputPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={confirmPassword}
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {!loading ? (
                    <Button className="w-100" variant="primary" type="submit">
                        Sign Up
                    </Button>
                ) : (
                    <Button className="w-100" variant="primary" type="submit" disabled>
                        Signing Up...
                    </Button>
                )}
                {/* <div className="d-grid  mt-3">
                    <Button
                        className="text-muted px-0"
                        variant="link"
                        onClick={handlePassword}
                        style={{ textAlign: "center" }}
                    >
                        Forgot password?
                    </Button>
                </div> */}
                <div className="mt-3">
                    <p className="text-center mb-0">
                        Already have an account? <Link to="/login"><span>Log In</span></Link>
                    </p>
                </div>
            </Form>
        </div>
    );
};

export default Register;
