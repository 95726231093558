import React from 'react'
import Header from '../Header'
import Section1 from './Section1'
import Footer from '../Footer'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'

const WhyApex = () => {
    return (
        <div>
            <Header />
            <Section1 />
            <Section2 />
            <Section3/>
            <Section4/>
            <Footer />
        </div>
    )
}

export default WhyApex