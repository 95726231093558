import React from "react";
import HomePage from "../components/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Education from "../components/Education";
import Products from "../components/Products";
import WhyApex from "../components/WhyApex";
import Login from "../components/Login";
import Register from "../components/Register";
import { AuthProvider } from "../service/authService";
import Dashboard from "../components/Admin/Dashboard";
import AuthGuard from "../service/authGuard";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/education" element={<Education />} />
          <Route path="/products" element={<Products />} />
          <Route path="/why-apex" element={<WhyApex />} />
          <Route
            path="/admin-dashboard"
            element={
              // <AuthGuard>
                <Dashboard />
              // </AuthGuard>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
