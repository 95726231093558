import React from 'react'
import './style.css'

const Section1 = () => {
    return (
        <>
            <div className='mt-5 pt-5 container'>
                <div class="snipcss-ZOJDY">
                    <div class="section-header">
                        <h1 class="section-header__title">
                            All that you require to begin trading
                        </h1>
                        <p>
                            Are you a novice trader and attempting to sort out where to begin? Then, you've found out the ideal place! Here we've hand-picked our top instructive assets for new traders, including articles, courses and online classes, so pick your favorite method of figuring out how to get everything rolling!
                        </p>

                    </div>
                    <div class="ed-levels__items ed-levels-desktop">
                        <div class="ed-levels__item">
                            <div class="ed-levels__item-inner">
                                <div class="ed-levels__item-icon">
                                    <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-date-range-blue.svg" alt="arrow right" />
                                </div>
                                <div class="ed-levels__item-title">
                                    Available on-demand, to fit your schedule
                                </div>
                            </div>
                        </div>
                        <div class="ed-levels__item">
                            <div class="ed-levels__item-inner">
                                <div class="ed-levels__item-icon">
                                    <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-education-blue.svg" alt="arrow right" />
                                </div>
                                <div class="ed-levels__item-title">
                                    Learn online, when and where it suits you
                                </div>
                            </div>
                        </div>
                        <div class="ed-levels__item">
                            <div class="ed-levels__item-inner">
                                <div class="ed-levels__item-icon">
                                    <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-money-off-blue.svg" alt="arrow right" />
                                </div>
                                <div class="ed-levels__item-title">
                                    100% free, so you have more money to trade
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-container ed-levels__items ed-levels-mobile swiper-container-initialized swiper-container-horizontal">
                        <div class="swiper-wrapper style-EH1iq" id="style-EH1iq">
                            <div class="swiper-slide">
                                <div class="ed-levels__item">
                                    <div class="ed-levels__item-inner">
                                        <div class="ed-levels__item-icon">
                                            <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-date-range-blue.svg" alt="arrow right" />
                                        </div>
                                        <div class="ed-levels__item-title">
                                            Available on-demand, to fit your schedule
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="ed-levels__item">
                                    <div class="ed-levels__item-inner">
                                        <div class="ed-levels__item-icon">
                                            <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-education-blue.svg" alt="arrow right" />
                                        </div>
                                        <div class="ed-levels__item-title">
                                            Learn online, when and where it suits you
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="ed-levels__item">
                                    <div class="ed-levels__item-inner">
                                        <div class="ed-levels__item-icon">
                                            <img src="https://static-images.admiralmarkets.com/images/elements/small-icons/icon-money-off-blue.svg" alt="arrow right" />
                                        </div>
                                        <div class="ed-levels__item-title">
                                            100% free, so you have more money to trade
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-footer">
                            <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                            </div>
                        </div>
                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true">
                        </span>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Section1