import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Section6 = () => {
  return (
    <div>
      <div className="section-6 mt-5 ">
        {/* <img src="" alt="Try demo trading" loading="lazy" /> */}
        <div class="mt-5 try-demo__wrapper container">
          <div style={{ alignSelf: "center" }} class="try-demo__info">
            <h2>Try Basic trading</h2>
            <p>
              You are not sure how to start? We`ve got you covered! Start
              practicing trading with minimum funds on the Apex demo trading
              account.
            </p>
            <Link to="/register">
              <button
                id="jrd-home-on-demo-2"
                class="mdc-button action-link mdc-button--color-positive mdc-button--unelevated mdc-button--large mdc-ripple-upgraded"
                color="positive"
                size="large"
              >
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">START TRADING</span>
              </button>
            </Link>
          </div>
          <div class="try-demo__cards">
            <div class="try-demo__cards-items">
              <div class="try-demo__cards-item">
                <div class="try-demo__cards-item-image">
                  <img
                    src="https://static-images.admiralmarkets.com/images/elements/trading/icon-mood-green.svg"
                    alt="No stress"
                    loading="lazy"
                    width="40"
                    height="40"
                  />
                </div>
                <p>No stress</p>
              </div>
              <div class="try-demo__cards-item centered">
                <div class="try-demo__cards-item-image">
                  <img
                    src="https://static-images.admiralmarkets.com/images/elements/trading/icon-money-green.svg"
                    alt="No deposit"
                    loading="lazy"
                    width="42"
                    height="48"
                  />
                </div>
                <p>No deposit</p>
              </div>
              <div class="try-demo__cards-item">
                <div class="try-demo__cards-item-image">
                  <img
                    src="https://static-images.admiralmarkets.com/images/elements/trading/icon-no-cc-green.svg"
                    alt="No credit card"
                    loading="lazy"
                    width="40"
                    height="40"
                  />
                </div>
                <p>No credit card</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
