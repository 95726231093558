import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Section4 = () => {
  return (
    <div>
      <section class="mt-5 pt-5 how-works snipcss-d1NbJ">
        <div class="mt-4 container">
          <h2 className="how-it-works-title" style={{ textAlign: "center", fontSize: "50px" }}>
            How it works
          </h2>
          <div class="mt-5 how-works__items">
            <div class="how-works__item blue">
              <div class="how-works__item-num">1</div>
              <div class="how-works__item-description">
                <h3 class="how-works__item-title">Register</h3>
                <p class="how-works__item-desc">
                  Sign up with your name and email address to start trading
                </p>
              </div>
            </div>
            <div class="how-works__item dark-green">
              <div class="how-works__item-num">2</div>
              <div class="how-works__item-description">
                <h3 class="how-works__item-title">Fund</h3>
                <p class="how-works__item-desc">Start trading from €250</p>
              </div>
            </div>
            <div class="how-works__item green">
              <div class="how-works__item-num">3</div>
              <div class="how-works__item-description">
                <h3 class="how-works__item-title">Trade</h3>
                <p class="how-works__item-desc">
                  Log in and start trading more than 8,000 instruments!
                </p>
              </div>
            </div>
          </div>
          <div class="how-works__link">
            <Link to="/register">
              <button
                class="mdc-button action-link mdc-button--color-positive mdc-button--unelevated mdc-button--large mdc-ripple-upgraded"
                size="large"
                color="positive"
              >
                <div class="mdc-button__ripple"></div>
                <span class="mdc-button__label">SIGN UP NOW</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section4;
