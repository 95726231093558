import React, { useEffect, useState } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import UserTable from "../AdminDashboard/ListUsers";
import "./style.css";
import SingelUserData from "../UserDashboard";
import { getUserById } from "../../../service/api";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Dashboard", "1", <UserOutlined />),
  getItem("Users", "2", <TeamOutlined />),
  getItem("Logout", "12", <UserOutlined />),
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Add state for selected menu item
  const [userData, setUserData] = useState();
  const userId = Cookies.get("uid");

  const isAdmin = Cookies.get("isAdmin") === "true"; // Check if user is admin based on cookies

  const handleMenuClick = (item) => {
    if (item.key === "12") {
      Cookies.remove("isAdmin");
      Cookies.remove("uid");
      navigate("/login");
    } else {
      setSelectedItem(item.key);
    }
  };

  useEffect(() => {
    // Set default selected item based on isAdmin status
    setSelectedItem(isAdmin ? "2" : "1");
  }, [isAdmin]);

  useEffect(() => {
    getUserById(userId).then((response) => {
        setUserData(response?.data)
    })
  }, []);
  const menuItems = isAdmin
    ? items.filter((item) => item.key === "2" || item.key === "12")
    : items.filter((item) => item.key !== "2");

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <h3 style={{ textAlign: "center", color: "white", marginTop: "30px" }}>
          Apex
        </h3>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          // items={items}
          items={menuItems}
          onClick={handleMenuClick}
          style={{ marginTop: "20px" }}
        />
      </Sider>
      <Layout>
        <Header
          style={{ padding: 0, background: colorBgContainer, color: "black" }}
        >
          <h2>{selectedItem?.key}</h2>
          <div className="header-css">
            <span className="header-font">Welcome, {userData?.username}</span>
          </div>
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}></Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              color: "black",
            }}
          >
            {/* Show content based on the selected item */}
            {selectedItem === "1" && (
              <div>
                <SingelUserData singleUser={userData} />
              </div>
            )}
            {selectedItem === "2" && (
              <div>
                <UserTable />
              </div>
            )}
            {/* {selectedItem === '8' && <div></div>}
                        {selectedItem === '9' && <div></div>}
                        {selectedItem === '10' && <div> </div>}
                        {selectedItem === '11' && <div> </div>} */}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
