import React from 'react';
import './style.css';

const Section3 = () => {
    return (
        <div>
            <section className="mt-5 trade snipcss-AOyIl">
                <div className="container">
                    <h2 className='small-screen-trade-title' style={{ textAlign: "center", fontSize: "50px" }}>
                        Trade and invest in 8,000+ markets today
                    </h2>
                    <div className="mt-3 trade__items">
                        <div className="trade__item">
                            <div className="trade__item-image trade-forex">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icon-forex.svg" alt="Forex" width="60" height="64" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    Crypto
                                </strong>
                                <p>
                                    80 CFDs on currency pairs
                                </p>
                            </div>
                        </div>
                        <div className="trade__item">
                            <div className="trade__item-image trade-indices">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icon-indices.svg" alt="Indices" width="60" height="60" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    Indices
                                </strong>
                                <p>
                                    43 Indices CFDs, including cash CFDs and Index Futures
                                </p>
                            </div>
                        </div>
                        <div className="trade__item">
                            <div className="trade__item-image trade-share">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icon-share.svg" alt="Stocks" width="50" height="54" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    Stocks
                                </strong>
                                <p>
                                    3000+ Share CFDs, as well as the ability to invest in thousands of shares
                                </p>
                            </div>
                        </div>
                        <div className="trade__item">
                            <div className="trade__item-image trade-commodities">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icon-commodities.svg" alt="Commodities" width="54" height="54" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    Commodities
                                </strong>
                                <p>
                                    CFDs on metals, energies and agriculture commodities
                                </p>
                            </div>
                        </div>
                        <div className="trade__item">
                            <div className="trade__item-image trade-bonds">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icons-bonds.svg" alt="Bonds" width="64" height="60" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    Forex
                                </strong>
                                <p>
                                    US Treasuries and Germany Bund CFDs
                                </p>
                            </div>
                        </div>
                        <div className="trade__item">
                            <div className="trade__item-image trade-ETFs">
                                <img src="https://static-images.admiralmarkets.com/images/elements/trading/icon-ETFs.svg" alt="ETFs" width="54" height="54" loading="lazy" />
                            </div>
                            <div className="trade__item-info">
                                <strong>
                                    ETFs
                                </strong>
                                <p>
                                    370+ ETF CFDs, plus hundreds of ETFs available through Invest.MT5
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Section3;
