import React, { useState, useEffect } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { message, Popconfirm, Spin, Modal, Input, Switch } from 'antd';
import "./style.css";
import { getUsers, deleteUser, updateUser } from "../../../service/api"; // Assuming you have API functions for getting, deleting, and editing users

const UserTable = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editedUsername, setEditedUsername] = useState('');
    const [editedFirstName, setEditedFirstName] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [editedPhoneNumber, setEditedPhoneNumber] = useState('');
    const [editedCountry, setEditedCountry] = useState('');
    const [editedReferralID, setEditedReferralID] = useState('');
    const [editedBalance, setEditedBalance] = useState('');
    const [editedTotalProfit, setEditedTotalProfit] = useState('');
    const [editedTotalBonuses, setEditedTotalBonuses] = useState('');
    const [editedReferralBonus, setEditedReferralBonus] = useState('');
    const [editedTotalDeposited, setEditedTotalDeposited] = useState('');
    const [editedTotalWithdraw, setEditedTotalWithdraw] = useState('');
    const [editedManagedAccounts, setEditedManagedAccounts] = useState('');
    const [isAdmin, setIsAdmin] = useState(false); // State for Admin toggle

    useEffect(() => {
        setLoading(true);
        getUsers()
            .then((response) => {
                setUserData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
                setLoading(false);
            });
    }, []);

    const showSuccessMessage = (msg) => {
        message.success({
            content: msg,
            duration: 2, // Display duration in seconds
        });
    };

    const showErrorMessage = (msg) => {
        message.error({
            content: msg,
            duration: 4, // Display duration in seconds
        });
    };

    const handleEdit = (userId, user) => {
        setEditingUser(user);
        setEditModalVisible(true);
        // Populate the edit form with user data
        setEditedUsername(user.username);
        setEditedFirstName(user.full_name);
        setEditedEmail(user.email);
        setEditedPhoneNumber(user.phone_number);
        setEditedCountry(user.country);
        setEditedReferralID(user.referral_id);
        setEditedBalance(user.balance);
        setEditedTotalProfit(user.total_price);
        setEditedTotalBonuses(user.total_bonus);
        setEditedReferralBonus(user.referal_bonus);
        setEditedTotalDeposited(user.total_deposited);
        setEditedTotalWithdraw(user.total_withdraw);
        setEditedManagedAccounts(user.managed_accounts)
        setIsAdmin(user.isAdmin); // Set the isAdmin toggle based on user data
    };

    const handleDelete = (userId) => {
        deleteUser(userId)
            .then(() => {
                showSuccessMessage("User deleted successfully.");
                // Remove the deleted user from state
                setUserData(userData.filter(user => user._id !== userId));
            })
            .catch((error) => {
                console.error("Error deleting user:", error);
                showErrorMessage("Failed to delete user.");
            });
    };

    const handleUpdate = () => {
        const updatedUserData = {
            _id: editingUser._id,
            username: editedUsername,
            full_name: editedFirstName,
            email: editedEmail,
            phone_number: editedPhoneNumber,
            country: editedCountry,
            referral_id: editedReferralID,
            balance: editedBalance,
            total_price: editedTotalProfit,
            total_bonus: editedTotalBonuses,
            referal_bonus: editedReferralBonus,
            total_deposited: editedTotalDeposited,
            total_withdraw: editedTotalWithdraw,
            managed_accounts: editedManagedAccounts, 
            isAdmin: isAdmin // Include isAdmin in the updated user data
        };
        updateUser(editingUser._id, updatedUserData)
            .then(() => {
                showSuccessMessage("User updated successfully.");
                // Update the user data in state
                setUserData(userData.map(user => user._id === editingUser._id ? updatedUserData : user));
                setEditModalVisible(false);
            })
            .catch((error) => {
                console.error("Error updating user:", error);
                showErrorMessage("Failed to update user.");
            });
    };

    return (
        <>
            {loading ? (
                <Spin style={{display: "flex", justifyContent: "center", alignSelf: "center"}} className='align-center' size='large' />
            ) : (
                <div className="relative overflow-x-scroll sm:rounded-lg mt-8">
                    <table className="overflow-x-auto w-100 text-sm text-left rtl:text-right">
                        <thead className="text-xs font-semibold text-gray-400">
                            <tr className="px-6 py-4">
                                <th scope="col" className="px-6 py-4"><strong>Full Name</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>User Name</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Email</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Phone no</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Country</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Referral ID</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Balance</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Is Admin</strong></th> {/* Added column for is Admin */}
                                <th scope="col" className="px-6 py-4"><strong>Edit</strong></th>
                                <th scope="col" className="px-6 py-4"><strong>Delete</strong></th>
                            </tr>

                        </thead>
                        <tbody>
                            {userData?.map((user, index) => (
                                <tr key={index} className="border-b hover:bg-offwhite">
                                    <td className="px-6 py-4">{user?.full_name}</td>
                                    <td className="px-6 py-4">{user?.username}</td>
                                    <td className="px-6 py-4">{user?.email}</td>
                                    <td className="px-6 py-4">{user?.phone_number}</td>
                                    <td className="px-6 py-4">{user?.country}</td>
                                    <td className="px-6 py-4">{user?.referral_id}</td>
                                    <td className="px-6 py-4">{user?.balance}</td>
                                    <td className="px-6 py-4">
                                        <Switch checked={user.isAdmin} /> {/* Display isAdmin toggle */}
                                    </td>
                                    <td className="px-6 py-4">
                                        <EditOutlined color="blue" style={{ color: "darkgreen" }} onClick={() => handleEdit(user.id, user)} />
                                    </td>
                                    <td className="px-6 py-4">
                                        <Popconfirm
                                            title="Delete this user?"
                                            onConfirm={() => handleDelete(user._id)}
                                            onCancel={() => { }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined style={{ color: "darkgreen" }} />
                                        </Popconfirm>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div >
            )}

            {/* Edit Modal */}
            <Modal
                title="Edit User"
                visible={editModalVisible}
                onOk={handleUpdate}
                onCancel={() => setEditModalVisible(false)}
            >
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="editedFirstName">First Name:</label>
                    <Input id="editedFirstName" value={editedFirstName} onChange={(e) => setEditedFirstName(e.target.value)} />
                </div>
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="editedUsername">Username:</label>
                    <Input id="editedUsername" value={editedUsername} onChange={(e) => setEditedUsername(e.target.value)} />
                </div>
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="editedEmail">Email:</label>
                    <Input id="editedEmail" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} />
                </div>
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="editedPhoneNumber">Phone Number:</label>
                    <Input id="editedPhoneNumber" value={editedPhoneNumber} onChange={(e) => setEditedPhoneNumber(e.target.value)} />
                </div>
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="editedCountry">Country:</label>
                    <Input id="editedCountry" value={editedCountry} onChange={(e) => setEditedCountry(e.target.value)} />
                </div>
                <div className="input-wrapper-main1">
                    <div className="input-wrapper-some1" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedReferralID">Referral ID:</label>
                        <Input id="editedReferralID" value={editedReferralID} onChange={(e) => setEditedReferralID(e.target.value)} />
                    </div>
                    <div className="input-wrapper-some1" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="editedManagedAccounts">Managed Accounts:</label>
                            <Input id="editedManagedAccounts" value={editedManagedAccounts} onChange={(e) => setEditedManagedAccounts(e.target.value)} />
                    </div>
                </div>
                <div className="input-wrapper-main" >
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedBalance">Balance:</label>
                        <Input id="editedBalance" value={editedBalance} onChange={(e) => setEditedBalance(e.target.value)} />
                    </div>
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedTotalProfit">Total Profit:</label>
                        <Input id="editedTotalProfit" value={editedTotalProfit} onChange={(e) => setEditedTotalProfit(e.target.value)} />
                    </div>
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedTotalBonuses">Total Bonuses:</label>
                        <Input id="editedTotalBonuses" value={editedTotalBonuses} onChange={(e) => setEditedTotalBonuses(e.target.value)} />
                    </div>
                </div>
                <div className="input-wrapper-main">
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedReferralBonus">Referral Bonus:</label>
                        <Input id="editedReferralBonus" value={editedReferralBonus} onChange={(e) => setEditedReferralBonus(e.target.value)} />
                    </div>
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedTotalDeposited">Total Deposited:</label>
                        <Input id="editedTotalDeposited" value={editedTotalDeposited} onChange={(e) => setEditedTotalDeposited(e.target.value)} />
                    </div>
                    <div className="input-wrapper-some" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="editedTotalWithdraw">Total Withdraw:</label>
                        <Input id="editedTotalWithdraw" value={editedTotalWithdraw} onChange={(e) => setEditedTotalWithdraw(e.target.value)} />
                    </div>
                </div>
                <div className="input-wrapper" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="isAdmin">Is Admin:</label>
                    <Switch checked={isAdmin} onChange={(checked) => setIsAdmin(checked)} />
                </div>
            </Modal>

        </>
    );
};

export default UserTable;
