import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Section2 = () => {
  return (
    <>
      <div>
        <div
          style={{ marginTop: "100px" }}
          class="container snipcss-gKrDN  pt-5"
        >
          <div class="section-header">
            <h2 class="section-header__title">Our online courses</h2>
            <p>
              Follow our guided courses to get the best understanding of
              trading, all created by our Industrial traders and financial
              advisers to help you get the best start in your trading journey.
            </p>
          </div>
          <div class="ed-courses__items">
            <div class="ed-courses__item ed-courses__image">
              <img
                src="https://static-images.admiralmarkets.com/images/elements/education/zero-to-hero-trade.jpg"
                alt=""
              />
            </div>
            <div class="ed-courses__item">
              <div class="ed-courses__item-inner">
                <h3 class="ed-courses__item-title">
                  Beginner to Professional: Learn to trade in 30 days
                </h3>
                <p>
                  Take a deeps dive into Crypto trading, with 30 days of
                  illustrations that take you from hypothetical trading concepts
                  like specialized and basic examination, through to
                  straightforward trading systems and how to make your most
                  memorable trades.
                </p>
                <p>The best part? It’s 100% free!</p>
                <Link to="/register">
                  <button
                    id="cy-education-for-free-1"
                    class="mdc-button action-link mdc-button--color-primary mdc-button--outlined mdc-button--large mdc-ripple-upgraded"
                    variant="outlined"
                    size="large"
                  >
                    <div class="mdc-button__ripple"></div>
                    <span class="mdc-button__label">REGISTER NOW</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div class="ed-courses__items ed-courses-reverse">
            <div class="ed-courses__item ed-courses__image">
              <img
                src="https://static-images.admiralmarkets.com/images/elements/education/course-bg.jpg"
                alt=""
              />
              <div class="ed-courses__image-title">
                <span className="crypto-small-scren">Crypto 101</span>
              </div>
            </div>
            <div class="ed-courses__item">
              <div class="ed-courses__item-inner">
                <h3 class="ed-courses__item-title">
                  Crypto 101: The Crypto and CFD Trading course
                </h3>
                <p>
                  Get a quick start with our financial advisers, written notes
                  and quizzes. The first three lessons are open to all, and then
                  you can start putting your knowledge into practice with the
                  help of financial advisers, who will teach you how to
                  carefully enter and exit a trade.
                </p>
                <Link to="/register">
                  <button
                    id="cy-education-for-free-2"
                    class="mdc-button action-link mdc-button--color-primary mdc-button--outlined mdc-button--large mdc-ripple-upgraded"
                    variant="outlined"
                    size="large"
                  >
                    <div class="mdc-button__ripple"></div>
                    <span class="mdc-button__label">REGISTER NOW</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section2;
