import logo from './logo.svg';
import './App.css';
import HomePage from './components/Homepage';
import Header from './components/Header';
import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from '@chakra-ui/react'
import AppRoutes from './routes/route';
import { AuthProvider } from './service/authService';

const { Button } = chakraTheme.components

const theme = extendBaseTheme({
  components: {
    Button,
  },
})

function App() {
  return (
    <ChakraBaseProvider theme={theme}>
        <AppRoutes />
    </ChakraBaseProvider>

  );
}

export default App;
